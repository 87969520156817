$primary:       #71446D;
$secondary:     #37474f;
$success:       #326F51;
$info:          #3366CC;
$warning:       #c09853;
$danger:        #AA2222;
$light:         #eeeeee;
$dark:          #444444;

$link-color: $primary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$web-font-path: false;
@import "./open_sans";

@import "~bootswatch/dist/spacelab/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/spacelab/bootswatch";

.navbar.bg-light .navbar-brand:hover {
  color: $primary;
}

.navbar .divider-vertical {
  height: 40px;
  margin: 0px 8px 0px 1px;
  border-left: 1px solid gray("500");
}

.bg-gray-2 { background-color: gray("200"); }

.badge.badge-warning { color: #ffffff; }
.btn.btn-warning { color: #ffffff; }
