.paperIllusion {
  background-color: #888888;
  padding: 1px;
}

.paper {
  margin: 10px;
  background-color: #FFFFFF;
  padding: 10px;
  border: 1px solid #444444;
}
