/* jQuery Countdown styles 1.6.2. */
.hasCountdown {
  border: 1px solid #ccc;
  background-color: #eee;
  padding-top: 5px;
  width: 200px;
  height: 45px;
}
.countdown_rtl {
  direction: rtl;
}
.countdown_holding span {
  color: #888;
}
.countdown_row {
  clear: both;
  width: 100%;
  padding: 0px 2px;
  text-align: center;
}
.countdown_show1 .countdown_section {
  width: 98%;
}
.countdown_show2 .countdown_section {
  width: 48%;
}
.countdown_show3 .countdown_section {
  width: 32.5%;
}
.countdown_show4 .countdown_section {
  width: 24.5%;
}
.countdown_show5 .countdown_section {
  width: 19.5%;
}
.countdown_show6 .countdown_section {
  width: 16.25%;
}
.countdown_show7 .countdown_section {
  width: 14%;
}
.countdown_section {
  display: block;
  float: left;
  font-size: 75%;
  text-align: center;
}
.countdown_amount {
  font-size: 200%;
}
.countdown_descr {
  display: block;
  width: 100%;
}
